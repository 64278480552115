<template>
  <Layout>

    <h1 class="uk-heading-small uk-text-center" id="content">Fepatex Machine Dashboard</h1>
      <div class="uk-child-width-1-5@m uk-grid-small uk-grid-match" uk-grid>
        <div scope="row" v-for="devices in devices" :key="devices.id" >
          <router-link :to="'/devices/' + devices.id"><h3 class="uk-card-title uk-text-center">{{ devices.devicename }}</h3>
          
            <div class="uk-card uk-card-default uk-card-body uk-text-center" v-bind:style="
              [devices.status == 'running' ? { 'background-color': ' #6AB187 ' } :
              devices.status == 'standby' ? { 'background-color': ' #EA6A47 ' } :
              devices.status == 'power_off' ? { 'background-color': ' #1C4E80 ' }:
               { 'background-color': ' #D32D41 ' }]" >
          <h1 class="">{{ devices.counter.toLocaleString() }}</h1>
        </div></router-link>
      </div>
    </div>
    <div>
</div>
  </Layout>
</template>
  
  
  
<script>

import DeviceService from "../services/device.service";
import EventBus from "../common/EventBus";
import Layout from '../layouts/private.vue';
import {DateTime} from 'luxon';

export default {
  name: "Devices Overview",
  components: { Layout },
  data() {
    return {
      content: "Devices",
      devices: "",
    };
  },
  created() {
    this.devicesRerender(),
      this.timer = setInterval(this.devicesRerender, 3000);
  },
  methods: {
    format_date(value){
         if (value) {
           return DateTime.fromISO(value).toRelative()
          }
      },
    devicesRerender() {
      DeviceService.getDevices().then(
        (response) => {
          this.devices = response.data.results;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    dismissed() {
      console.log('Alert dismissed')
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    }
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  }
};

</script>
  